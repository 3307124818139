import { createContext } from "react"

import type { DialogRootProps } from "./dialog"

type DialogContextState = {
  size: DialogRootProps["size"]
  theme: DialogRootProps["theme"]
  shouldCloseOnOverlayClick: boolean
}

const defaultValue: DialogContextState = {
  size: "md",
  theme: "auto",
  shouldCloseOnOverlayClick: true,
}

export const DialogContext = createContext(defaultValue)
