import { Suspense, lazy } from "react"

import { TrackedComponent, TrackedComponents } from "@spatialsys/react/analytics"
import { Dialog } from "@spatialsys/web/ui"

const RenameModalContent = lazy(() =>
  import(/* webpackChunkName: "rename-modal-content" */ "./rename-modal-content").then((mod) => ({
    default: mod.RenameModalContent,
  }))
)

interface RenameModalProps {
  onConfirm: (newName: string) => void
  onDeny: () => void
  onDismiss: () => void
  name: string
}

/** We cannot directly use ConfirmModal since we want to wrap the entire modal body in `form` */
export const RenameModal = (props: RenameModalProps) => {
  const { onDismiss, name } = props

  return (
    <Dialog open={Boolean(name)} onRequestClose={onDismiss}>
      <TrackedComponent id={TrackedComponents.RenameSpaceModal}>
        <Suspense fallback={null}>
          <RenameModalContent {...props} />
        </Suspense>
      </TrackedComponent>
    </Dialog>
  )
}
