import { useEffect } from "react"

import { useAppContext } from "../app-context"

type ModalCountProps = React.PropsWithChildren<{
  /**
   * Whether or not this modal should block hotkeys from being triggered.
   * Defaults to true.
   *
   * @default true
   */
  blockHotkeys?: boolean
}>

export function useCountModal(blockHotkeys = true) {
  const actions = useAppContext((context) => context.actions)
  useEffect(() => {
    actions.incrementModalCount(blockHotkeys)
    return () => actions.decrementModalCount(blockHotkeys)
  }, [actions, blockHotkeys])
}

/**
 * Render this to increment the modal count. This should be rendered inside
 * components like dialogs, modals, drawers, popovers, etc.
 *
 * We track the number of open modals in order to:
 * - Prevent certain hotkeys from being triggered when a modal is open
 * - Refocus the Unity canvas when all modals are closed (i.e. `openModalCount` transitions to 0)
 *
 * This component does not render anything onto the DOM. You can also use the {@link useCountModal} hook.
 */
export function ModalCount(props: ModalCountProps) {
  useCountModal(props.blockHotkeys)
  return props.children
}
