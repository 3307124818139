import { memo } from "react"

import { ModalCount } from "@spatialsys/web/app-context"
import {
  ConfirmModalBase,
  ConfirmModalBaseProps,
} from "@spatialsys/web/core/js/components/confirm-modal/confirm-modal-base"
import { Dialog, DialogBody, DialogProps } from "@spatialsys/web/ui"

type ConfirmModalProps = DialogProps &
  ConfirmModalBaseProps & {
    onDismiss: () => void
    withModalCount?: boolean
  }

export const ConfirmModal = memo(function ConfirmModal(props: ConfirmModalProps) {
  const { onDismiss, withModalCount, ...rest } = props

  return (
    <Dialog onRequestClose={onDismiss} {...rest}>
      <DialogBody>
        {withModalCount ? (
          <ModalCount>
            <ConfirmModalBase {...rest} />
          </ModalCount>
        ) : (
          <ConfirmModalBase {...rest} />
        )}
      </DialogBody>
    </Dialog>
  )
})
