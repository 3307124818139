import { memo } from "react"

import { FeedTitle } from "@spatialsys/js/sapi/types"
import { Heading } from "@spatialsys/web/ui"

type SectionHeaderProps = {
  title: FeedTitle
}

export const SectionHeader = memo(function SectionHeader(props: SectionHeaderProps) {
  return (
    <Heading size="h4" weight="black" className="inline whitespace-pre-wrap">
      {props.title.map((words, i) => {
        return <span key={i}>{words.label}</span>
      })}
    </Heading>
  )
})
