import { memo, useCallback, useMemo, useState } from "react"

import { SpaceAndCreator, SpaceMetadata } from "@spatialsys/js/sapi/types"
import { getIsPaidSpaceSub } from "@spatialsys/js/sapi/utils/get-is-paid-space-sub"
import { TrackedComponent, TrackedComponents } from "@spatialsys/react/analytics"
import { useDeleteSpaceMutation, useEditSpaceMutation } from "@spatialsys/react/query-hooks/sapi/spaces"
import { useGetSpaceLimitsQuery } from "@spatialsys/react/query-hooks/spaces-v2/get-space-limits"
import { trackRoomDelete } from "@spatialsys/web/analytics"
import { useAppContext, useAuthState } from "@spatialsys/web/app-context"
import { Modals } from "@spatialsys/web/app-state"
import { ConfirmDelete } from "@spatialsys/web/core/js/components/confirm-delete/confirm-delete"
import { ConfirmModal } from "@spatialsys/web/core/js/components/confirm-modal/confirm-modal"
import { Button, toast } from "@spatialsys/web/ui"

import { SpacesGridItem, SpacesGridItemProps } from "../spaces-grid-item/spaces-grid-item"
import { VideoPlayingContextProvider, useVideoPlayingContext } from "../video-playing-context"
import { RenameModal } from "./rename-modal/rename-modal"

import spacesGridClasses from "../spaces-feed/spaces-grid/spaces-grid.module.scss"

export type SpacesListGridProps = Pick<
  SpacesGridItemProps,
  | "asButton"
  | "isLoadingLoved"
  | "onSelect"
  | "openCreatorProfileInNewTab"
  | "handleSetBanner"
  | "showSpaceSubscriptionPlan"
> & {
  enableFakePagination?: boolean
  spaces: SpaceAndCreator[]
  /** A list of space IDs to hide */
  spacesToFilter?: string[]
  containerClassName?: string
  showHoverPreview?: boolean
  sectionIndex?: number
}

function TrackedSpacesListGrid({ componentId, ...props }: SpacesListGridProps & { componentId?: TrackedComponents }) {
  if (!componentId) {
    return <SpacesListGridWithVideoPlayingContext {...props} />
  }
  return (
    <TrackedComponent id={componentId}>
      <SpacesListGridWithVideoPlayingContext {...props} />
    </TrackedComponent>
  )
}

export { TrackedSpacesListGrid as SpacesListGrid }

export function SpacesListGridWithVideoPlayingContext(props: SpacesListGridProps) {
  const contextAlreadyExists = useVideoPlayingContext((context) => context.activeVideo !== undefined)
  if (contextAlreadyExists) {
    return <SpacesListGrid {...props} />
  }
  return <VideoPlayingContextProvider>{<SpacesListGrid {...props} />}</VideoPlayingContextProvider>
}

const SpacesListGrid = memo(function SpacesListGrid(props: SpacesListGridProps) {
  const {
    enableFakePagination,
    spaces,
    spacesToFilter,
    containerClassName = spacesGridClasses.gridContainer,
    showHoverPreview,
    sectionIndex,
    showSpaceSubscriptionPlan,
    ...rest
  } = props

  const { isAuthenticated } = useAuthState()

  const { mutate: editSpace } = useEditSpaceMutation()
  const { mutate: deleteSpace, isLoading: isDeletingSpace } = useDeleteSpaceMutation()
  const actions = useAppContext((context) => context.actions)
  const { activeVideo, setActiveVideo } = useVideoPlayingContext()
  const openLoginModal = useCallback(
    () => actions.openModal({ type: Modals.Login, payload: { titleCta: "Sign in to continue" } }),
    [actions]
  )
  const [spaceToRename, setSpaceToRename] = useState<SpaceMetadata | null>(null)
  const [spaceToDelete, setSpaceToDelete] = useState<SpaceMetadata | null>(null)

  const { data: spaceToDeleteLimits } = useGetSpaceLimitsQuery(spaceToDelete?.id as string, {
    enabled: Boolean(spaceToDelete?.id),
  })

  const isSpaceToDeletePaidSpaceSub = getIsPaidSpaceSub(spaceToDeleteLimits?.plan)

  const closeRenameSpaceModal = useCallback(() => setSpaceToRename(null), [])
  const closeDeleteSpaceModal = useCallback(() => setSpaceToDelete(null), [])
  const onConfirmRenameSpace = useCallback(
    (newName) => {
      if (newName !== spaceToRename.name)
        editSpace(
          { name: newName, roomId: spaceToRename.id },
          {
            onError: () => toast.error("Error updating the space's name, try again later"),
            onSuccess: () => toast.success(`Your space has been renamed.`),
          }
        )
      setSpaceToRename(null)
    },
    [editSpace, spaceToRename]
  )

  const onConfirmDeleteSpace = useCallback(() => {
    if (!spaceToDelete) {
      return
    }
    trackRoomDelete()
    deleteSpace(
      { roomId: spaceToDelete.id },
      {
        onError: () => toast.error("Error deleting room, try again later"),
        onSuccess: () => {
          closeDeleteSpaceModal()
          toast.success(`${spaceToDelete.name} has been deleted.`)
        },
      }
    )
  }, [deleteSpace, spaceToDelete, closeDeleteSpaceModal])

  const handleDeleteSpace = useCallback((space: SpaceMetadata) => {
    setSpaceToDelete(space)
  }, [])

  const handleRenameSpace = useCallback((space: SpaceMetadata) => {
    setSpaceToRename(space)
  }, [])

  const onThumbnailPointerOver = useCallback(
    (index: number) => {
      setActiveVideo(index, sectionIndex)
    },
    [setActiveVideo, sectionIndex]
  )

  // We implement client-side pagination so users don't have to scroll past ~100 spaces to access marketing info
  // Keep track of total spaces loaded to determine when to display "More Space" button
  const totalSpaces = spaces?.length || 0

  const pageSize = 12

  const [visibleSpacesCount, setVisibleSpacesCount] = useState(pageSize)

  const showMoreSpaces = useCallback(() => setVisibleSpacesCount(visibleSpacesCount + pageSize), [visibleSpacesCount])

  const spacesToRender = useMemo(() => {
    const filtered = spacesToFilter
      ? spaces.filter((spaceAndCreator) => !spacesToFilter.includes(spaceAndCreator.space.id))
      : spaces

    if (isAuthenticated || !enableFakePagination) {
      return filtered
    }
    return filtered.slice(0, visibleSpacesCount)
  }, [enableFakePagination, isAuthenticated, spaces, spacesToFilter, visibleSpacesCount])

  return (
    <>
      <div className={containerClassName}>
        {spacesToRender?.map((data, index) => (
          <SpacesGridItem
            key={index}
            spaceData={data}
            darkenImageWhileVideoLoading
            handleLogin={openLoginModal}
            handleDeleteSpace={handleDeleteSpace}
            handleRenameSpace={handleRenameSpace}
            onSelect={props.onSelect}
            spaceListIndex={index}
            showHoverPreview={showHoverPreview}
            onThumbnailPointerOver={onThumbnailPointerOver}
            isVideoThumbnailPlaying={activeVideo?.[0] === index && activeVideo?.[1] === sectionIndex}
            showSpaceSubscriptionPlan={showSpaceSubscriptionPlan}
            {...rest}
          />
        ))}
      </div>

      {visibleSpacesCount < totalSpaces && !isAuthenticated && enableFakePagination && (
        <div className="mt-8 flex justify-center pb-12">
          <Button size="lg" onClick={showMoreSpaces}>
            More Spaces
          </Button>
        </div>
      )}

      {/* Modals for renaming and deleting a space through the overflow menu */}
      <RenameModal
        onConfirm={onConfirmRenameSpace}
        onDeny={closeRenameSpaceModal}
        onDismiss={closeRenameSpaceModal}
        name={spaceToRename?.name}
      />
      <ConfirmModal
        open={Boolean(spaceToDelete)}
        onConfirm={onConfirmDeleteSpace}
        onDeny={closeDeleteSpaceModal}
        onDismiss={closeDeleteSpaceModal}
        title={`Are you sure you want to delete ${spaceToDelete?.name}?`}
        confirmText="Yes"
        denyText="Cancel"
        subtitle={<ConfirmDelete isPaidSpaceSub={isSpaceToDeletePaidSpaceSub} />}
        isLoading={isDeletingSpace}
      />
    </>
  )
})
