import { memo } from "react"

import { Heading } from "@spatialsys/web/ui"

type ConfirmDeleteProps = {
  isPaidSpaceSub: boolean
}

export const ConfirmDelete = memo(function ConfirmDelete({ isPaidSpaceSub }: ConfirmDeleteProps) {
  return (
    <Heading asChild size="h4" className="text-red">
      <p>
        {isPaidSpaceSub
          ? "Deleting this space will remove all space data and cancel the current plan within the next billing cycle."
          : "Are you sure you want to delete this space and all its data?"}
      </p>
    </Heading>
  )
})
