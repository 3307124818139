import { PricingPlanId } from "../types"

/**
 * Helper function to check if a space is a paid space subscription
 *
 * Centralize the logic in case there are changes to the planId's in the future
 */
export function getIsPaidSpaceSub(pricingPlanId: PricingPlanId | undefined) {
  if (!pricingPlanId) return false

  return pricingPlanId === "PRO" || pricingPlanId === "BUSINESS" || pricingPlanId === "ENTERPRISE"
}
