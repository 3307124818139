import Link from "next/link"
import { memo, useCallback, useMemo } from "react"

import { ReactComponent as KeyboardArrowRightIcon } from "@spatialsys/assets/icons/material-filled/keyboard-arrow-right.svg"
import { SpacesGridFeedComponentData } from "@spatialsys/js/sapi/types"
import { TrackedComponents, useTrackInteraction } from "@spatialsys/react/analytics"
import { RoomJoinMethod } from "@spatialsys/unity/app-state"
import { SpaceJoinContextSource, createSpaceClickEvent, createSpaceMetadataProperties } from "@spatialsys/unity/bridge"
import { useAppContext } from "@spatialsys/web/app-context"
import { SectionHeader } from "@spatialsys/web/core/js/components/spaces-feed/section-header/section-header"
import {
  SpacesListGrid,
  SpacesListGridProps,
} from "@spatialsys/web/core/js/components/spaces-list-grid/spaces-list-grid"

import classes from "./spaces-grid.module.scss"

type SpaceGridProps = SpacesGridFeedComponentData & { sectionIndex: number }

export const SpacesGrid = memo(function SpacesGrid(props: SpaceGridProps) {
  const { title, numRows, link, spaces, analyticsContext } = props

  const trackInteraction = useTrackInteraction()
  const actions = useAppContext((context) => context.actions)
  const gridClassName = useMemo(() => {
    if (numRows === undefined) return classes.spaceRow
    if (numRows === 1) return classes.spacesListGridOneRow
    if (numRows === 2) return classes.spacesListGridTwoRows
    if (numRows === 3) return classes.spacesListGridThreeRows
    return classes.spaceRow
  }, [numRows])

  const handleSelectSpace = useCallback<NonNullable<SpacesListGridProps["onSelect"]>>(
    (data, metadata) => {
      const spaceProperties = createSpaceMetadataProperties(data.space)
      actions.setSpaceJoinContext({
        method: RoomJoinMethod.UserSelected,
        spaceProperties,
        discoveryMetadata: {
          ...metadata,
          "Section Index": props.sectionIndex,
          Source: SpaceJoinContextSource.HomeFeed,
          ...analyticsContext,
        },
      })
      trackInteraction(
        ...createSpaceClickEvent({
          ...metadata,
          ...spaceProperties,
          "Section Index": props.sectionIndex,
          Source: SpaceJoinContextSource.HomeFeed,
          ...analyticsContext,
        })
      )
    },
    [actions, analyticsContext, props.sectionIndex, trackInteraction]
  )

  return (
    <div className="space-y-2">
      <Link
        href={link}
        className="group -mx-2.5 inline-flex flex-row items-center rounded-md px-2.5 py-[3px] no-underline transition-colors hover:bg-accent focus:bg-accent"
      >
        <SectionHeader title={title} />
        <KeyboardArrowRightIcon className="icon icon-md transition-transform group-hover:translate-x-1 group-focus:translate-x-1" />
      </Link>
      <div className={numRows === undefined ? classes.rowContainer : undefined}>
        <SpacesListGrid
          containerClassName={gridClassName}
          spaces={spaces}
          componentId={TrackedComponents.FeedSpacesList}
          onSelect={handleSelectSpace}
          sectionIndex={props.sectionIndex}
        />
      </div>
    </div>
  )
})
